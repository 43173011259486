import styled from '@emotion/styled'
import { Link } from 'gatsby'
import React from 'react'
import ThanksgivingHornImage from '../assets/images/svg/christmas-bells.svg'
import Layout from '../components/layout'
import { buttonStyles, plantin, plantinBoldItalic } from '../styles'

const ThankYouWrap = styled.div({
  minHeight: '80vh',
  display: 'flex',
  alignItems: 'center',
  textAlign: 'center',
  justifyContent: 'center',
  flexDirection: 'column',
})

const ThankYouHeader = styled.h1({
  fontFamily: plantinBoldItalic,
  letterSpacing: -0.5,
  fontSize: '4.4rem',
  marginBottom: '1.5rem',
})

const styles = {
  fill: 'red',
  width: '100%',
  maxWidth: '100%',
}

const ImageWrapper = styled.div({
  marginBottom: '3rem',
  maxWidth: '100%',
  padding: '0 2rem',
  '@media screen and (min-width: 720px)': {
    maxWidth: '50%',
  },
})

const ReturnLink = styled.div({
  margin: '2rem 0',
})

const Copy = styled.p({
  fontFamily: plantin,
  margin: 0,
})

const ChristmasThankYouPage = () => (
  <Layout>
    <ThankYouWrap>
      <ImageWrapper>
        <ThanksgivingHornImage style={styles} />
      </ImageWrapper>
      <ThankYouHeader>Thank You For Your Order!</ThankYouHeader>
      <Copy>
        We look forward to seeing you on either the December 23
        <sup>rd</sup> or before 8PM on Christmas Eve.
      </Copy>
      <ReturnLink>
        <Link
          css={{
            // ...buttonStyles,
            // ...buttonHoverStyles,
            textDecoration: 'none',
            padding: '2rem 2rem',
          }}
          to="/"
        >
          Return to our home page
        </Link>
      </ReturnLink>
    </ThankYouWrap>
  </Layout>
)

export default ChristmasThankYouPage
